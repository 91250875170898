import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import InformationBox from 'common/common/InformationBox';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import Button from 'common/inputs/Button';
import TextInput from 'common/inputs/TextInput';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import Spinner from 'common/Spinner';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import Tappable from 'common/Tappable';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminZendeskSettings.scss';

const OAuthPath = '/oauth/authorizations/new';
const ZendeskClientID = publicConfig('zendeskClientID');
const ZendeskSubdomainRegex = /^[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?$/i;

class AdminZendeskSettings extends Component {
  static propTypes = {
    company: PropTypes.object,
    location: PropTypes.object,
    openModal: PropTypes.func,
    router: PropTypes.object,
  };

  state = {
    error: null,
    loading: false,
    needsReinstall: this.props.company.zendesk?.lostAccess,
    registering: false,
    subdomain: this.props.company.zendesk?.subdomain ?? '',
  };

  componentDidMount() {
    const {
      company: { zendesk },
      location,
      router,
    } = this.props;
    const { code, subdomain } = location.query;
    if (!code || !subdomain) {
      if (zendesk && !zendesk.lostAccess) {
        this.testToken();
      }
      return;
    }

    if (!subdomain.match(ZendeskSubdomainRegex)) {
      return;
    }

    this.setState({
      registering: true,
    });

    router.replace({
      pathname: location.pathname,
    });

    AJAX.post(
      '/api/zendesk/register',
      {
        code,
        subdomain,
      },
      (response) => {
        this.setState({
          error: null,
          registering: false,
        });

        const { reloadCompany } = this.props;
        reloadCompany();
      }
    );
  }

  getOAuthURL = () => {
    const subdomain = this.state.subdomain.trim();
    if (subdomain === '') {
      return 'javascript:void(0)';
    }

    const { company, location } = this.props;
    const query = queryString.stringify({
      client_id: ZendeskClientID,
      redirect_uri: this.getRedirectURI(),
      response_type: 'code',
      scope: 'apps:write read',
      state: JSON.stringify({
        ...(location.query.connect && {
          redirect: true,
        }),
        subdomain: company.subdomain,
        zendeskSubdomain: subdomain,
      }),
    });
    return 'https://' + subdomain + '.zendesk.com' + OAuthPath + query;
  };

  testToken = async () => {
    this.setState({ loading: true });
    const response = await AJAX.post('/api/zendesk/testToken');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error?.type === 'invalid token') {
      this.setState({
        loading: false,
        needsReinstall: true,
      });
      return;
    }

    this.setState({ loading: false });
  };

  getRedirectURI = () => {
    const baseURL = devURL('https://canny.io');
    const path = '/zendesk';
    return baseURL + path;
  };

  onDisconnect = () => {
    const { openModal } = this.props;
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to disconnect your Zendesk account?",
      onConfirm: () => {
        AJAX.post('/api/zendesk/disconnect', {}, (response) => {
          if (response !== 'success') {
            this.setState({
              error: 'Something went wrong, please contact our team for support.',
            });
          } else {
            this.setState({
              error: null,
              registering: false,
            });
            const { reloadCompany } = this.props;
            reloadCompany();
          }
        });
      },
    });
  };

  onSubdomainChange = (e) => {
    this.setState({
      subdomain: e.target.value,
    });
  };

  renderConnected() {
    const {
      company: { zendesk },
    } = this.props;
    const { needsReinstall } = this.state;
    if (!zendesk || needsReinstall) {
      return null;
    }

    return (
      <div className="connected">
        <div className="text">Your Zendesk account is connected.</div>
        <div className="text">Account name: {zendesk.name}</div>
        <div className="text">Account subdomain: https://{zendesk.subdomain}.zendesk.com</div>
        <Tappable onTap={this.onDisconnect}>
          <div className="disconnect">Disconnect</div>
        </Tappable>
      </div>
    );
  }

  renderDisconnected() {
    const {
      activeIntegrations: { integrationCount, integrationLimit },
      company: { zendesk },
    } = this.props;
    if (zendesk) {
      return null;
    }

    if (integrationLimit && integrationCount >= integrationLimit) {
      return <AdminIntegrationLimitUpsell />;
    }

    const { registering } = this.state;
    const subdomain = this.state.subdomain.trim();
    const zendeskURL = this.getOAuthURL();
    return (
      <div className="add">
        <div className="text">
          Enter your Zendesk subdomain to install our integration. You must be a Zendesk Admin and a
          Canny Admin to successfully complete this process.
        </div>
        <TextInput
          inset="Subdomain"
          onChange={this.onSubdomainChange}
          prefix="https://"
          suffix=".zendesk.com"
        />
        <a
          href={zendeskURL}
          className={subdomain === '' ? 'disabled' : ''}
          rel="noopener"
          target="_blank">
          <Button disabled={subdomain === ''} loading={registering} value="Add to Zendesk" />
        </a>
      </div>
    );
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }

    return <div className="error">{error}</div>;
  }

  renderReinstall() {
    const {
      company: { zendesk },
    } = this.props;
    const { needsReinstall, registering, subdomain } = this.state;
    if (!zendesk || !needsReinstall) {
      return null;
    }

    const zendeskURL = this.getOAuthURL();
    return (
      <>
        <InformationBox icon="!" className="withMarginBottom">
          Canny has lost access to your Zendesk account. Please reinstall the integration to
          continue using it. Alternatively, disconnect the integration completely.
        </InformationBox>
        <TextInput
          className="withMarginBottom"
          defaultValue={zendesk.subdomain}
          inset="Subdomain"
          onChange={this.onSubdomainChange}
          prefix="https://"
          suffix=".zendesk.com"
        />
        <a
          href={zendeskURL}
          className={subdomain === '' ? 'disabled' : ''}
          rel="noopener"
          target="_blank">
          <Button
            disabled={subdomain === ''}
            loading={registering}
            value="Reinstall Zendesk integration"
          />
        </a>
        <Tappable onTap={this.onDisconnect}>
          <div className="disconnect reinstall withMarginTop">Disconnect</div>
        </Tappable>
      </>
    );
  }

  renderContents() {
    const { company } = this.props;
    const { loading } = this.state;
    if (!company?.integrations?.zendesk) {
      return <AdminFeatureUpsell feature="zendesk" />;
    }

    if (loading) {
      return <Spinner />;
    }

    return (
      <div className="contents">
        {this.renderDisconnected()}
        {this.renderReinstall()}
        {this.renderConnected()}
        {this.renderError()}
      </div>
    );
  }

  render() {
    return (
      <div className="adminZendeskSettings">
        <Helmet title="Zendesk Integration | Canny" />
        <div className="settingsHeading">Zendesk Integration</div>
        <div className="content">
          <div className="description">
            <div className="text">
              Canny's Zendesk integration lets you keep track of feedback that customers give you in
              Zendesk&nbsp;tickets.
            </div>
          </div>
          {this.renderContents()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.zendesk), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminZendeskSettings);
