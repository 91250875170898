import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import { ActiveIntegrationContext } from 'common/containers/ActiveIntegrationsContainer';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { OpenModalContext } from 'common/containers/ModalContainer';
import connect from 'common/core/connect';
import publicConfig from 'common/core/publicConfig';
import Helmet from 'common/helmets/Helmet';
import LazyLoadedImage from 'common/LazyLoadedImage';
import ConfirmModal from 'common/modals/ConfirmModal';
import withAccessControl from 'common/routing/withAccessControl';
import AdminDirectMessagesSettings from 'common/subdomain/admin/AdminDirectMessagesSettings';
import AdminFeatureUpsell from 'common/subdomain/admin/AdminFeatureUpsell';
import AdminIntegrationChannelSettings from 'common/subdomain/admin/AdminIntegrationChannelSettings';
import AdminIntegrationLimitUpsell from 'common/subdomain/admin/AdminIntegrationLimitUpsell';
import devURL from 'common/util/devURL';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import queryString from 'common/util/queryString';
import withContexts from 'common/util/withContexts';

import 'css/components/subdomain/admin/_AdminSlackSettings.scss';

const SlackClientID = publicConfig('slackClientID');

const SlackSettings = {
  directMessages: 'directMessages',
  feedback: 'slack',
  channels: 'slackChannel',
};

const AddToSlackButton = ({ slackURL }) => (
  <a
    href={slackURL}
    className="addToSlackButton"
    rel="noreferrer noopener nofollow"
    target="_blank">
    <LazyLoadedImage
      alt="Add to Slack"
      height="40"
      width="139"
      src="https://platform.slack-edge.com/img/add_to_slack@2x.png"
      srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
    />
  </a>
);

class AdminSlackSettings extends Component {
  static propTypes = {
    boards: PropTypes.array,
    company: PropTypes.shape({
      _id: PropTypes.string,
      logoURL: PropTypes.string,
      name: PropTypes.string,
      subdomain: PropTypes.string,
      tintColor: PropTypes.string,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape({
        code: PropTypes.string,
      }),
    }),
    router: PropTypes.object,
  };

  state = {
    code: this.props.location.query.code,
    error: null,
    integration: this.props.location.query.integration,
  };

  componentDidMount() {
    const { code, integration } = this.state;
    if (!code) {
      return;
    }

    const { location, router } = this.props;
    router.replace({
      pathname: location.pathname,
    });

    if (integration === SlackSettings.feedback || integration === SlackSettings.directMessages) {
      this.addSlackPermissions(integration);
    } else if (SlackSettings.channels) {
      this.registerSlackChannel();
    }
  }

  async registerSlackChannel() {
    const { code } = this.state;
    this.setState({ error: null });

    const redirectURI = this.getRedirectURI();
    const response = await AJAX.post('/api/slack/registerChannel', { code, redirectURI });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'plan does not support':
          'Your current plan does not support Slack integration. Please upgrade to install it.',
        'integration limit':
          'You have reached the maximum limit for installed integrations. Please uninstall another integration before enabling Slack.',
        'already registered':
          'The Slack integration has already been installed. Try refreshing the page, or contact us.',
        'registered elsewhere':
          'This Slack channel is connected to another Canny instance, reach out to support to get this issue resolved.',
        'not authorized':
          'You are not authorized to install Slack. Please request a manager on your team to install it for you.',
      },
    });

    if (error) {
      this.setState({ error: error.message });
    } else {
      this.props.reloadCompany();
    }
  }

  async registerSlack(integration) {
    const { code } = this.state;
    this.setState({ error: null });

    const redirectURI = this.getRedirectURI();
    const response = await AJAX.post('/api/slack/register', { code, redirectURI, integration });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        'plan does not support':
          'Your current plan does not support Slack integration. Please upgrade to install it.',
        'integration limit':
          'You have reached the maximum limit for installed integrations. Please uninstall another integration before enabling Slack.',
        'already registered':
          'The Slack integration has already been installed. Try refreshing the page, or contact us.',
        'not authorized':
          'You are not authorized to install Slack. Please request a manager on your team to install it for you.',
      },
    });

    if (error) {
      this.setState({ error: error.message });
    } else {
      this.props.reloadCompany();
    }
  }

  async updateSlack(integrations) {
    const { code } = this.state;
    this.setState({ error: null });

    const redirectURI = this.getRedirectURI();
    const response = await AJAX.post('/api/slack/update', { code, integrations, redirectURI });

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      this.setState({ error: error.message });
    } else {
      this.props.reloadCompany();
    }
  }

  addSlackPermissions(integration) {
    const { company } = this.props;

    if (company.slack && (company.slack.feedback || company.slack.directMessages)) {
      const integrations = {
        directMessages: true,
        feedback: true,
      };
      this.updateSlack(integrations);
    } else {
      const integrations = {
        directMessages: integration === SlackSettings.directMessages,
        feedback: integration === SlackSettings.feedback,
      };
      this.registerSlack(integrations);
    }
  }

  reduceSlackPermissions(integration) {
    const { openModal, company } = this.props;

    this.setState({ error: null });
    openModal(ConfirmModal, {
      message: "Are you sure you'd like to disconnect your Slack integration?",
      onConfirm: () => {
        if (company.slack.feedback && company.slack.directMessages) {
          const integrations = {
            directMessages: integration !== SlackSettings.directMessages,
            feedback: integration !== SlackSettings.feedback,
          };

          this.updateSlack(integrations);
        } else {
          this.disconnectSlack();
        }
      },
    });
  }

  async disconnectSlack() {
    const response = await AJAX.post('/api/slack/disconnect', {});

    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });
    if (error) {
      this.setState({ error: error.message });
    } else {
      this.props.reloadCompany();
    }
  }

  getRedirectURI() {
    const {
      company: { subdomain },
    } = this.props;
    return devURL('https://canny.io/slack?subdomain=' + subdomain);
  }

  atIntegrationLimit() {
    const {
      activeIntegrations: { integrationCount, integrationLimit },
    } = this.props;
    return integrationLimit && integrationCount >= integrationLimit;
  }

  canAddIntegration() {
    const { company } = this.props;
    const atIntegrationLimit = this.atIntegrationLimit();
    const hasAccess = company?.integrations?.slack;

    const isUsingIntegration = company.slack || company.slackChannels.length > 0;
    return (hasAccess && !atIntegrationLimit) || isUsingIntegration;
  }

  renderUpsell() {
    if (this.canAddIntegration()) {
      return null;
    }

    if (this.atIntegrationLimit()) {
      return <AdminIntegrationLimitUpsell />;
    }

    return <AdminFeatureUpsell feature="slack" />;
  }

  renderAddDirectMessagesButton() {
    if (!this.canAddIntegration()) {
      return null;
    }
    const { company } = this.props;
    if (company.slack && company.slack.directMessages) {
      return (
        <button
          className="disconnect"
          onClick={() => this.reduceSlackPermissions(SlackSettings.directMessages)}>
          Disconnect
        </button>
      );
    }

    const redirectURI = this.getRedirectURI();
    const slackParams = {
      client_id: SlackClientID,
      redirect_uri: redirectURI,
      scope: 'chat:write,users:read,users:read.email',
      state: JSON.stringify({ integration: SlackSettings.directMessages }),
    };
    const slackURL = 'https://slack.com/oauth/v2/authorize' + queryString.stringify(slackParams);

    return <AddToSlackButton slackURL={slackURL} />;
  }

  renderAddChannelButton() {
    if (!this.canAddIntegration()) {
      return null;
    }

    const redirectURI = this.getRedirectURI();

    const slackParams = {
      client_id: SlackClientID,
      redirect_uri: redirectURI,
      scope: 'incoming-webhook,chat:write,users:read,users:read.email',
      state: JSON.stringify({ integration: SlackSettings.channel }),
    };
    const slackURL = 'https://slack.com/oauth/v2/authorize' + queryString.stringify(slackParams);
    return <AddToSlackButton slackURL={slackURL} />;
  }

  renderAddSlackButton() {
    const { company } = this.props;
    if (company.slack && company.slack.feedback) {
      return (
        <div className="connected">
          <p className="description">
            Your Slack Workspace ({company.slack.teamName}) is connected. <br />
            Check out&nbsp;
            <a
              href="https://help.canny.io/en/articles/766734-slack-integration"
              rel="noopener"
              target="_blank">
              this help article
            </a>
            &nbsp;to see how this integration works.
          </p>
          <button
            className="disconnect"
            onClick={() => this.reduceSlackPermissions(SlackSettings.feedback)}>
            Disconnect
          </button>
        </div>
      );
    }

    if (!this.canAddIntegration()) {
      return null;
    }

    const redirectURI = this.getRedirectURI();
    const slackParams = {
      client_id: SlackClientID,
      redirect_uri: redirectURI,
      scope: 'commands,chat:write,users:read,users:read.email',
      state: JSON.stringify({ integration: SlackSettings.feedback }),
    };
    const slackURL = 'https://slack.com/oauth/v2/authorize' + queryString.stringify(slackParams);
    return <AddToSlackButton slackURL={slackURL} />;
  }

  renderChannels() {
    const {
      boards,
      company: { slackChannels },
      reloadCompany,
    } = this.props;

    const integration = {
      deleteURL: '/api/slack/deleteChannel',
      editURL: '/api/slack/editChannel',
      getChannelHeading: (channel) => `${channel.teamName} : ${channel.channel}`,
      name: 'slack',
      reload: reloadCompany,
    };
    const channelItems = [];
    slackChannels.forEach((channel, i) => {
      channelItems.push(
        <AdminIntegrationChannelSettings
          boards={boards}
          channel={channel}
          integration={integration}
          key={i}
        />
      );
    });

    return <div className="channels">{channelItems}</div>;
  }

  render() {
    return (
      <div className="adminSlackSettings">
        <Helmet title="Slack Integration | Canny" />
        <h1 className="settingsHeading">Slack Integration</h1>
        <div className="content">
          <>
            <h2 className="subheading">Feedback</h2>
            <p className="description">
              Create posts and add votes on behalf of customers from within Slack. Great for when
              your team has product conversations in&nbsp;Slack.
            </p>
            {this.renderAddSlackButton()}
          </>

          <div className="notifications">
            <h2 className="subheading">Direct Message Notifications</h2>
            <p className="description">
              Get direct messages sent to tagged users on select events in Canny such as new posts
              or comments.
            </p>
            {this.renderAddDirectMessagesButton()}
            {this.props.company.slack?.directMessages && (
              <AdminDirectMessagesSettings settings={this.props.company.slack.settings} />
            )}
          </div>

          <div className="notifications">
            <h2 className="subheading">Channel Notifications</h2>
            <p className="description">
              Get instant notifications on select events in Canny such as new posts, comments, or
              votes. Click “Add to Slack” again to configure notifications in another&nbsp;channel.
            </p>
            {this.renderAddChannelButton()}
            {this.state.error ? <div className="slackError">{this.state.error}</div> : null}
            {this.renderChannels()}
          </div>

          {this.renderUpsell()}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, (dispatch) => ({
    reloadCompany: (post) => {
      return Promise.all([dispatch(reloadCompany())]);
    },
  })),
  withAccessControl(testEveryPermission(RoutePermissions.integrations.slack), '/admin/settings', {
    forwardRef: true,
  }),
  withContexts(
    {
      activeIntegrations: ActiveIntegrationContext,
      company: CompanyContext,
      openModal: OpenModalContext,
    },
    {
      forwardRef: true,
    }
  )
)(AdminSlackSettings);
